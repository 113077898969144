<template>
  <div id="states-networkSolutions">
    <v-container class="lighten-5">
      <v-container style="margin-bottom: 30px">
        <dashboard-core-app-bar />
      </v-container>
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      <form @submit.prevent="saveStates">
        <v-row>
          <v-col md="12">
            <v-btn type="submit" class="ma-2" color="primary" dark
              >Register<v-icon dark right
                >mdi-checkbox-marked-circle</v-icon
              ></v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card class="mx-auto">
              <v-card-title>Comme neuf</v-card-title>

              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field
                    label="Nom"
                    v-model="likeNewState.name"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="État de fonctionnement"
                    v-model="likeNewState.operatingState"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-card class="mx-auto">
              <v-card-title>Très bon état</v-card-title>

              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field
                    label="Nom"
                    v-model="veryGoodState.name"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="État de fonctionnement"
                    v-model="veryGoodState.operatingState"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-card class="mx-auto">
              <v-card-title>Bon état</v-card-title>

              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field
                    label="Nom"
                    v-model="goodState.name"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="État de fonctionnement"
                    v-model="goodState.operatingState"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-card class="mx-auto">
              <v-card-title>Etat correct</v-card-title>

              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field
                    label="Nom"
                    v-model="correctState.name"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="État de fonctionnement"
                    v-model="correctState.operatingState"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import db from "./../../../../../firebaseInit";
import NodesName from "./../../../../utils/nodesName";
import NodesFieldName from "./../../../../utils/nodesFieldName";
import FirebaseDB from "./../../../../utils/firebaseDB";

export default {
  name: "states-networkSolutions",
  components: {
    DashboardCoreAppBar: () =>
      import("../../../../materialDashboard/components/core/AppBar.vue"),
  },
  data() {
    return {
      type: null,
      likeNewState: {
        name: null,
        operatingState: null,
      },
      veryGoodState: {
        name: null,
        operatingState: null,
      },
      goodState: {
        name: null,
        operatingState: null,
      },
      correctState: {
        name: null,
        operatingState: null,
      },
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Catalog Settings",
          disabled: false,
          to: "/Mercarue/catalogs/catalogSettings",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    db.collection(NodesName.PRODUCT_STATES)
      .where(NodesFieldName.UNIQUE_ID, "==", to.params.type)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size == 1) {
          querySnapshot.forEach((doc) => {
            next((vm) => {
              vm.type = doc.data().uniqueId;
              vm.likeNewState = doc.data().likeNewState;
              vm.veryGoodState = doc.data().veryGoodState;
              vm.goodState = doc.data().goodState;
              vm.correctState = doc.data().correctState;
            });
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          next((vm) => {
            vm.type = to.params.type;
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  },
  methods: {
    saveStates() {
      FirebaseDB.saveStatesOfTypeOfProducts(
        this.$router,
        this.type,
        this.likeNewState,
        this.veryGoodState,
        this.goodState,
        this.correctState
      );
    },
  },
};
</script>
